import React from "react";
import { Link } from "react-router-dom";
import {ReactComponent as MobileViewImg} from '../../assets/images/mobile-view.svg';

function Intro() {
  return (
    <div className="flex items-center justify-center w-full bg-[url('./assets/images/pattern-v.png')] sm:bg-none bg-fit text-white sm:text-black">
      <div className="sw flex flex-wrap items-center py-10">
        <div className="flex-1 flex flex-col p-4">
          <p className="text-white sm:text-gray-500">FixerWithin</p>

          {/* Big Header Content */}
          <h1>Find Verified Fixers Nearby</h1>
          <p className="text-white sm:text-gray-500 py-10">Find a fixer that is ready to work, available and nearby.<br /> Book the fixer for the same day or schedule  for any later date.</p>
          <Link to='/' className='btn-sty2'>Learn More</Link>
        </div>

        {/* SVG Mobile View Image */}
        <div className="flex-1 flex justify-center">
          <MobileViewImg className="h-[500px] w-[300px] min-w-[300px]" />
        </div>
      </div>
    </div>
  )
}

export default Intro;