import React from "react";
import MobileView2 from '../../assets/images/MobileView2.png'
import Nearby from '../../assets/images/Nearby-Icon.png';
import Rate from '../../assets/images/Rate-Icon.png';
import Chat from '../../assets/images/Chat-Icon.png';


function MoreFeatures() {
  return (
    <div className="w-full flex justify-center">
      <div className="sw">
        <p>More Features</p>
        <h2>Explore Our App Features</h2>
        <div className="flex items-stretch justify-center ">

          {/* Row 1 - Features */}
          <div className="hidden sm:flex flex-1 flex-col gap-16 justify-center">
            <div className="flex flex-col gap-3 ">
              <div className="w-14 h-14">
                <img src={Nearby} alt='Nearby' />
              </div>
              <h4>Nearby</h4>
              <p>Find a fixer that is ready to work, available and nearby</p>
            </div>
            <div className="flex flex-col gap-3 ">
              <div className="w-14 h-14">
                
              </div>
              <h4>schedule</h4>
              <p>Book the fixer for the same day or schedule  for any later date.</p>
            </div>
          </div>

          {/* Row Center - Big Mobile Image */}
          <div className="">
            <img src={MobileView2} alt="App" className="h-[500px] sm:h-[700px] min-w-[300px]" />
          </div>
          
          {/* Row 3 */}
          <div className="hidden sm:flex flex-1 flex-col gap-16 justify-center ">
            <div className="flex flex-col gap-3">
              <div className="w-14 h-14">
                <img src={Chat} alt='Nearby' />
              </div>
              <h4>Direct Chat</h4>
              <p>Call or messages the fixers directly.</p>
            </div>
            <div className="flex flex-col gap-3">
              <div className="w-14 h-14">
                <img src={Rate} alt='Nearby' />
              </div>
              <h4>Ratings</h4>
              <p>Leave good ratings for any fixer or express your disappointments</p>
            </div>
          </div>
        </div>

        {/* Steps Block */}
        <Steps />
      </div>
    </div>
  )
}

function Steps() {
  return (
    <div className="flex flex-col gap-4 py-10 mb-20">
      <p> 3 STEPS</p>
      
      {/* Big Header */}
      <h2 className="py-4">You'll Love<br /> to use it</h2>
      <div className="flex flex-wrap justify-center gap-4">

        {/* Cards */}

        <div className="card-sty1 min-w-[200px] flex-1">
          <div className='rounded-full bg-fixer inline-block w-14 h-14 flex justify-center items-center text-white font-extrabold text-2xl '>1</div>
          <h4 className="py-4">Create your account</h4>
          <p>
            Easily create an account and you are ready to search for Fixers around you
          </p>
        </div>

        <div className="card-sty1 min-w-[200px] flex-1">
          <div className='rounded-full bg-fixer inline-block w-14 h-14 flex justify-center items-center text-white font-extrabold text-2xl '>2</div>
          <h4 className="py-4">Search</h4>
          <p>
            Search and filter Fixers around you. Select the one that matches your need.
          </p>
        </div>

        <div className="card-sty1 min-w-[200px] flex-1">
          <div className='rounded-full bg-fixer inline-block w-14 h-14 flex justify-center items-center text-white font-extrabold text-2xl '>3</div>
          <h4 className="py-4">Book and Rate</h4>
          <p>
            Book the Fixer and review
          </p>
        </div>
      </div>
    </div>
  )
}

export default MoreFeatures;