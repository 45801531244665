import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Index from "./screens/LandingPage/index";
import FAQ from "./screens/LandingPage/FAQ";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navbar />}>
                    <Route index element={<Index />} />
                    <Route path="/faq" element={<FAQ />} />
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
