import React, { useEffect, useState } from "react";
import Rate from "../../components/Rate";


// Sample Data
const TestimonialList = [
  {
    name: "Alexander Obi",
    testimony: "I got the best hand on FixerWithin. Highly recommend the team",
    img: null,
  },
  {
    name: "Ijeoma Olivia",
    testimony: "I got the best hand on FixerWithin. Highly recommend the team",
    img: null,
  },
  {
    name: "Adamu Lawal",
    testimony: "I got the best hand on FixerWithin. Highly recommend the team",
    img: null,
  }
]

function Testimonials() {
  const [testimonialList,setTestimonialList] = useState([])

  useEffect(() => {
    setTestimonialList(TestimonialList);
  },[])

  return (
    <div className="w-full flex justify-center py-10 my-10">
      <div className="sw flex items-center flex-wrap">

        {/* Big Header */}
        <div className="flex-1 flex flex-col gap-3 px-10">
          <p className="uppercase">Testimonials</p>
          <h1>Customers<br />Love Our App</h1>
          <p>I got the best hand on FixerWithin. Highly recommend the team.</p>

          {/* Rate Calculator Component */}
          <div><Rate value={4.5} className='flex' /></div>
        </div>

        <div className="flex-1 flex flex-col gap-4 my-10">
          {
            // Listing Sample Data from variable
            testimonialList.map((list,i) => (
              <div className="card-sty1 min-w-[300px] quote flex" key={i}>
                <div className="p-1">
                  <img src={list.img || null} alt='User' className="w-20 h-20 avatar" />
                </div>
                <div className="p-4">
                  <h3>{list.name}</h3>
                  <p>"{list.testimony}"</p>
                </div>
              </div>  
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Testimonials;