import React from "react";
import { Link } from "react-router-dom";
import InstantBooking from '../../assets/images/InstantBooking.png'
import RateFixers from '../../assets/images/RateFixers.png'
import Download from "../../components/Download";

function Features() {
  return (
    <div className="w-full flex justify-center">
      <div className="sw flex flex-col">

        {/* First Feature */}
        <div className="flex flex-wrap justify-between ">
          <div className="flex-1 flex justify-center">
            <img src={InstantBooking} alt='Instant Booking' className="w-[300px] max-w-[300px] max-h-[500px] -translate-x-10" />
          </div>

          {/* Big header Text */}
          <div className="flex-1 flex flex-col justify-center mb-40">
            <p>Features</p>
            <h2>Instant Booking & scheduling</h2>
            <p>Book the fixer for the same day or schedule  for any later date.</p>
            <Link to="/" className='btn-sty3 self-baseline'>Get App</Link>
          </div>
        </div>


        {/* Second Feature */}
        <div className="flex flex-row-reverse flex-wrap justify-between -translate-y-20">
          <div className="flex-1 sm:scale-125 flex justify-center">
            <img src={RateFixers} alt='Instant Booking' className="w-[300px] max-w-[300px] max-h-[500px] " />
          </div>

          {/* Big Header Text */}
          <div className="flex-1 flex flex-col justify-center ">
            <p>Features</p>
            <h2>Easily rate the fixers</h2>
            <div className="py-4">Leave good ratings for any fixer or express your disappointments</div>
            <Link to="/" className='btn-sty3 self-baseline'>Get App</Link>
            <p className="py-4 list-sty1 flex flex-col gap-2">
              <li>Choose the best</li>
              <li>Help others select the best</li>
              <li>Keep our platform safe</li>
            </p>
          </div>
        </div>

        {/* Download Component */}
        <Download>Download The App <br /> From The Store</Download>
      </div>
    </div>
  )
}

export default Features;