import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";

function Navbar() {
    const [menuDisplay, setMenuDisplay] = useState("h-0");
    const [open, setOpen] = useState("");

    // Menu Links
    const Links = [
        <Link key="1" to="/">
            About
        </Link>,
        <Link key="2" to="/">
            Features
        </Link>,
        <Link key="2" to="/faq">
            FAQ
        </Link>,
        <Link key="3" to="/" className="btn-sty1 my-4 text-blue-600">
            Get App Now
        </Link>,
    ];
    // Menu Opener
    function handleMenu() {
        setOpen(open === "open" ? "" : "open");
        setMenuDisplay(open === "open" ? "max-h-0" : "max-h-[100%]");
    }

    return (
        <div className="flex flex-col items-center">
            {/* Nav Header */}
            <div className="sw py-3 flex justify-between items-center z-20 ">
                <h3 className="">FixerWithin</h3>
                <div className="sm:hidden">
                    <label className={"menu-btn " + open} onClick={handleMenu}>
                        <span></span>
                    </label>
                </div>
                <div className="hidden sm:flex gap-10 items-center">
                    {[...Links]}
                </div>
            </div>

            {/* Nav Header For Mobile View */}
            <div
                className={
                    "absolute shadow-xl shadow-black/30 bg-white w-full flex justify-center overflow-hidden transition-all sm:hidden " +
                    menuDisplay
                }
            >
                <div className="sw pt-14 flex justify-end text-black z-10 ">
                    <div className="flex flex-col py-4 gap-6 text-right">
                        {[...Links]}
                    </div>
                </div>
            </div>
            <Outlet />
        </div>
    );
}

export default Navbar;
