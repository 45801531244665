import React from "react";
import Intro from "./Intro";
import WhyFixer from "./WhyFixer";
import Features from "./Features";
import MoreFeatures from "./MoreFeatures";
import FAQ from "./FAQ";
import Testimonials from "./Testimonials";
import OurBlog from "./OurBlog";
import Footer from "./Footer";

function Index() {
    return (
        <div className="flex flex-col justify-center self-stretch">
            <Intro />
            <WhyFixer />
            <Features />
            <MoreFeatures />
            {/* <FAQ /> */}
            <Testimonials />
            <OurBlog />
            <Footer />
            <div className="p-10">&nbsp;</div>
        </div>
    );
}

export default Index;
