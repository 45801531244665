import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import downArrow from "../../assets/images/down-arrow.svg";
import upArrow from "../../assets/images/up-arrow.svg";
import Footer from "./Footer";
// Sample Data
const faqList = [
    {
        q: "What makes you different from other service providers?",
        ans: "We care about you, the customer. We only provide you with quality and vetted fixers who can deliver value to you at a competitive rate and on time.",
        category: "General",
    },
    {
        q: "When are you open? What are your business hours",
        ans: "We run a 24-hour platform but availability is dependent on the selected fixer.",
        category: "General",
    },
    {
        q: "What kind of work can a fixer do?",
        ans: "Not all fixers are created equally. The skill sets a fixer possesses, will dictate what jobs you should hire them to perform. The following are a few of the services a fixer will likely perform - plumbing, painting, welding and tiling.",
        category: "General",
    },
    {
        q: "Should You Tip A Handyman?",
        ans: "Tipping is not expected, nor is it calculated into your bill. Most fixers will charge a fixed rate that compensates them for their time, labor, and supplies used in the job. Tip if you want to.",
        category: "General",
    },
    {
        q: "Do I provide the materials necessary for my project?",
        ans: "In some cases we prefer that you purchase some of the items needed for a job. Items such as light fixtures, faucets, toilets, shelves, ceiling fans, tile, laminate flooring, and others that are to our customer's liking or preference. Other items such as drywall (and other pertinent materials related to drywall repairs), wires, cables, junction boxes, and other specific parts for repairs are better purchased by fixer, so that we get the correct ones.",
        category: "General",
    },
    {
        q: "Do I need to be at the property at the time of service?",
        ans: "It is not absolutely necessary, but we prefer to have someone at the home that is at least 18 years old. You may arrange for us to gain access to your property and to lock it after we leave.",
        category: "General",
    },
    {
        q: "What kind of services do you offer?",
        ans: "We offer painting, plumbing, electrical, small remodeling of kitchen and bathrooms, and other jobs.",
        category: "General",
    },
    {
        q: "Do you charge by the job or by the hour?",
        ans: "Our fixers charge by the job, NOT by the hour for most jobs. When the fixer estimate jobs and gives price estimate or range of cost of labor for a complete project or job. The reason why we charge by the job is really a benefit to our customers, so that if a project runs for longer than expected the customer will be charged the estimated price. ",
        category: "General",
    },
    {
        q: "Can A Handyman Do Electrical Work?",
        ans: "Similarly to plumbing work, handymen can only do electrical work if they are licensed to perform electrical work. Besides the basic switches and fixture installation state licensing is most likely required.",
        category: "General",
    },
    {
        q: "Do you require a deposit?",
        ans: "No, we don’t take any deposits. ",
        category: "Payment",
    },
    {
        q: "How much is your service charge?",
        ans: "FixerWithin does not charge any fee to the customer to use the platform. You only pay for the agreed fee with the selected fixer.",
        category: "Payment",
    },
    {
        q: "Can I deactivate my account",
        ans: "Yes, you can deactivate your account from your profile page.  We will not show your profile while deactivated.",
        category: "Account",
    },
    {
        q: "Can I delete my account",
        ans: "Yes, you can delete your account from your profile page.  We will delete your profile information from our database.",
        category: "Account",
    },
    {
        q: "Do you share my data with other third-parties?",
        ans: "No, we don’t share data with any third-party company or partner.",
        category: "Privacy",
    },
    {
        q: "How do I get support or contact your team",
        ans: "You can send us an email call or visit our office",
        category: "Support",
    },
];
function FAQ() {
    const [selectedCategory, setSelectedCategory] = useState("General");
    const [content] = useState(["General", "Payment", "Account", "Privacy", "Support"]);
    const navigate = useNavigate();
    const [hideDesc, setHideDesc] = useState([]);
    const handleHide = (index) => {
        const temp = [...hideDesc];
        if (temp[index]) {
            temp[index] = false;
        } else {
            temp[index] = true;
        }
        setHideDesc(temp);
    };
    return (
        <div className="w-full flex flex-col items-center justify-center ">
            <div className="sw flex flex-col justify-center ">
                <p className="text-6xl text-black font-black my-10">FAQs</p>
                <div className="rounded-3xl p-10 w-full flex justify-around md:flex-row flex-col px-10 gap-20">
                    <div className="border w-full md:w-80 h-fit rounded-lg p-5">
                        <span className="text-xl text-xl font-bold ">
                            Table of Content
                        </span>
                        <div className="flex flex-col gap-6 mt-5">
                            {content.map((elem) => (
                                <button
                                    className=" font-bold border-b text-base text-left"
                                    style={
                                        selectedCategory === elem
                                            ? { color: "#219653" }
                                            : {}
                                    }
                                    onClick={() => {
                                        setSelectedCategory(elem);
                                        document
                                            .getElementById(elem)
                                            .scrollIntoView({
                                                behavior: "smooth",
                                            });
                                    }}
                                >
                                    {elem}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="w-full">
                        {content.map((cate, catIndex) => (
                            <div className="w-full" key={catIndex}>
                                <span
                                    className="text-base font-extrabold"
                                    id={cate}
                                >
                                    {cate}
                                </span>
                                <div className="flex flex-col gap-6 my-5">
                                    {
                                        /* Listing questions and answers from a variable */
                                        faqList
                                            .filter(
                                                (elem) => elem.category === cate
                                            )
                                            .map((faq, i) => (
                                                <div
                                                    className="border p-5 rounded-lg w-full flex justify-between items-center"
                                                    key={i}
                                                    style={{
                                                        borderColor: "#219653",
                                                    }}
                                                >
                                                    <div>
                                                        <h4
                                                            className="inline-block w-full text-sm mb-3"
                                                            style={{
                                                                color: "#219653",
                                                            }}
                                                        >
                                                            {faq.q}
                                                        </h4>
                                                        <div
                                                            className={`py-1 pb-4 ${
                                                                hideDesc[i]
                                                                    ? "hidden"
                                                                    : "block"
                                                            }`}
                                                        >
                                                            {faq.ans}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button
                                                            className="text-black"
                                                            onClick={() => {
                                                                handleHide(i);
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    hideDesc[i]
                                                                        ? downArrow
                                                                        : upArrow
                                                                }
                                                                alt="arrow"
                                                                width={"20px"}
                                                                className="text-black"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default FAQ;
