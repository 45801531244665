import React from "react";
import { Link } from "react-router-dom";


function Download(props) {
  return (
    <div className="bg-fixer bg-[length:100%_100%] p-10 text-white flex flex-col items-center my-10">
      <span>Download Now</span>
      <h2 className="py-10 " style={{textAlign: 'center'}}>
        {props.children}
      </h2>
      <div className="flex gap-4">
        <Link to="/" className="btn-sty1 text-white">Explore</Link>
        <Link to="/" className="btn-sty2">Get Now</Link>
      </div>
    </div>
  )
}

export default Download;